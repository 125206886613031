.card-form {
  border-width: 0;
  border-radius: 6px;
  @include shadow-dreamy($gray-400);

  .card-header {
    padding: 20px;
    background-color: transparent;
    border-bottom-color: $gray-200;
  }

  .card-title {
    font-size: 15px;
    font-weight: $font-weight-semibold;
    color: $dark;
    margin-bottom: 5px;
  }

  .card-text {
    color: $secondary;
    margin-bottom: 0;
  }
}

.form-item {
  padding: 20px;
  position: relative;

  + .form-item { border-top: 1px solid $gray-200; }

  h6 { margin-bottom: 3px; }

  p {
    margin-bottom: 0;
    font-size: $font-size-xs;
    color: $secondary;
  }

  .table {
    white-space: nowrap;
    font-size: $font-size-sm;

    @include media-breakpoint-up(lg) { font-size: $font-size-base; }

    th {
      padding-top: 0;
      font-size: $font-size-sm;
    }

    td {
      vertical-align: middle;
      padding-top: 8px;
      padding-bottom: 8px;
    }

    th:first-child,
    td:first-child {
      padding-left: 0;
    }

    th:last-child,
    td:last-child {
      padding-right: 0;
    }

    th:not(:first-child),
    td:not(:first-child) {
      text-align: right;
    }

    > :not(:first-child),th,td { border-color: $gray-200; }

    nav {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    a {
      display: block;
      color: $secondary;
      font-size: 16px;
      line-height: 1;
      opacity: .6;

      &:hover {
        opacity: 1;
        color: $primary;
      }

      + a { margin-left: 5px; }
    }
  }
}